import React from "react"
import { graphql } from "gatsby"

import {Row} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap-grid.min.css'

import Layout from "../components/layout"


const TermsTemplate = ({data, pageContext: {lang, currentPath}}) => console.log('') || (
    
  <Layout wpLang={lang} currentPath={currentPath} pageTitle={data.wordpressPage.yoast_title} pageMeta={data.wordpressPage.yoast_meta} featuredImagePath="above-min.png">
<Row className="single-page">
<div className="entry-content">
<h1>Terms of Service</h1>
<h4>Definitions:</h4>
<p><strong>Gift Card Voucher</strong> – A bearer document with an alpha numeric code which can be used to redeem Dagcoins from the Dagcoin Gift Card website. It is not a epayments or a digital payments instrument.</p>
<p><strong>Website</strong> – means Dagcoin Gift Card Website.</p>
<p><strong>User</strong> – means the purchaser or holder of the Dagcoin Gift Card Voucher.</p>
<p><strong>T&amp;C</strong> – means the terms &amp; conditions given below, including any amendments, modifications or additions therein.</p>
<h4>GENERAL PROVISIONS</h4>
<ol>
<li>These T&amp;C set the rights and obligations of the User and Dagcoin Gift Card in obtaining and using Gift Card Voucher issued by Dagcoin Gift Card.</li>
<li>Gift Card Vouchers can be purchased from the Dagcoin Gift Card Website.</li>
<li>The information regarding the value of the Gift Card Voucher and the corresponding DagCoins that can be obtained shall be given on the Dagcoin Gift Card website.</li>
<li>Gift Card vouchers are valid for a period of 50 years.</li>
<li>Gift Card Voucher shall be issued exclusively, only to one bearer.</li>
<li>Dagcoin Gift Card reserves the rights for denying to issue gift cards to any user or denying the service to users from a particular country.</li>
<li>Gift Card vouchers cannot be used to redeem any fiat currency.</li>
<li>Gift card vouchers can be used by the purchaser or any other person to whom the purchaser gifts the Gift Card Voucher.</li>
<li>Losing a gift card voucher shall not entitle the user to get a replacement from Dagcoin Gift Card. Each gift card voucher has a unique alpha numeric code and if the user loses it, Dagcoin Gift Card shall not be responsible.</li>
</ol>
<h4>TERMS OF USE</h4>
<ol>
<li>After purchasing of Gift Card Voucher, the user can redeem it for Dagcoins on the Dagcoin Gift Card website by using the unique alpha numeric code given on the Gift Card Voucher.</li>
<li>A used Gift Card Voucher shall become redundant and can not be redeemed again.</li>
<li>User is not entitled to redeem the Gift Card Voucher in parts.</li>
<li>Dagcoin Gift Card reserves the right to ask the User to provide documents required for the KYC (Know Your Customer) procedure which may be necessary to comply with applicable AML (Anti-Money Laundering) legislation. Dagcoin Gift Card reserves the right to hold User transactions until User is verified according to AML laws, and may reject granting its services if such a procedure is not completed.</li>
<li>Dagcoin Gift Card shall not be held responsible for any financial losses in case if the Crypto Voucher is lost, destroyed or used without permission by an unauthorized person, or for any other reasons which are outside of Dagcoin Gift Card’s</li>
</ol>
<h4>RISK</h4>
<ol>
<li>The User acknowledges and agrees that cryptocurrency market is decentralized, characterized by substantial volatility and liquidity. Accordingly, the number of coins that user will get and the value of the coins may keep changing. However, once the Gift Card Voucher is purchased, the number of coins that can be redeemed shall remain stable.</li>
</ol>
<h4>BLOCKING OF GIFT CARD VOUCHERS</h4>
<ol>
<li>Dagcoin Gift Card reserves the right to temporarily or permanently block Gift Card Voucher validity if the user complains that the Gift Card Voucher is lost or stolen or if Dagcoin Gift Card receives information that it was acquired contrary to the provisions of law. The Gift Card Voucher will automatically lose validity upon its expiry date.</li>
</ol>
<h4>FORCE MAJEURE</h4>
<ol>
<li>Force Majeure shall mean any event which is outside Dagcoin Gift Card’s control and which interferes with the correct and accurate performance of all or any of the terms and conditions set herein or the services to be performed by Dagcoin Gift Card. Some examples of Force Majeure are given below:
<ol>
<li>riots, strikes, lack of electricity supply, fire, lack of connection, cataclysm, armed conflicts;</li>
<li>terrorist attacks and related events;</li>
<li>Technical failures;</li>
<li>Internet failures;</li>
<li>Unexpected website or server shutdowns.</li>
</ol>
</li>
<li>Upon the occurrence of an event of force majeure, Dagcoin Gift Card shall not be liable for any impediments, delays or failure to perform obligations pursuant to the provisions of these T&amp;C.</li>
</ol>
<h4>MODIFICATION CLAUSE</h4>
<ol>
<li>Dagcoin Gift Card has the right to amend these T&amp;C in which case Dagcoin Gift Card will notify the Users in advance.</li>
</ol>
<h4>USER’S RESPONSIBILITIES.</h4>
<ol>
<li>If the user is gifting the Gift Card Voucher to another person, the user shall inform him/her about the terms and conditions. The user shall clearly communicate that the Gift Card Voucher is not redeemable in cash. The User shall clearly communicate the date of expiry of the Gift Card Voucher which gifting the gift card.</li>
<li>If the user is a recipient of a Gift Card Voucher, he/she shall read these terms and conditions before proceeding to use or gift the Gift Card Voucher to someone else.</li>
<li>User shall inform the person to whom the Gift Card Voucher is beign gifted about Know Your Customer requirements and also pass the information that KYC information and documents can be asked by Dagcoin Gift Card at any time to comply with Anti Money Laundering Laws.</li>
<li>User shall not purchase or gift the Gift Card Vouchers in countries and jurisdictions where the buying or selling or using of cryptocurrency is not allowed as per the laws of that country/jurisdiction.</li>
</ol>
</div>           
        </Row>
  </Layout>
)
export const query = graphql`
  query($lang: String) {
    wordpressPage(
      slug: {eq: "terms-of-service"}
      wpml_current_locale: {eq: $lang}
    ) {
      title
      yoast_meta {
        name
        content
        property
      }
      yoast_title
        wpml_current_locale
    }



  }
`
export default TermsTemplate